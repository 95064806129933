/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const search = /* GraphQL */ `
  query Search(
    $words: String
    $domain: String
    $isRainforest: Boolean
    $saveHistory: Boolean
  ) {
    search(
      words: $words
      domain: $domain
      isRainforest: $isRainforest
      saveHistory: $saveHistory
    )
  }
`;
export const getProductDetail = /* GraphQL */ `
  query GetProductDetail(
    $asin: String
    $domain: String
    $isLite: Boolean
    $isDetail: Boolean
    $nocache: Boolean
    $countpv: Boolean
  ) {
    getProductDetail(
      asin: $asin
      domain: $domain
      isLite: $isLite
      isDetail: $isDetail
      nocache: $nocache
      countpv: $countpv
    ) {
      asin
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getShopList = /* GraphQL */ `
  query GetShopList($words: String!, $domain: String) {
    getShopList(words: $words, domain: $domain)
  }
`;
export const getOffers = /* GraphQL */ `
  query GetOffers(
    $asin: String!
    $page: Int
    $isConditionNew: Boolean
    $isConditionUsedLikeNew: Boolean
    $isConditionUsedVeryGood: Boolean
    $isConditionUsedGood: Boolean
    $isConditionUsedAcceptable: Boolean
    $isPrime: Boolean
    $isFreeShipping: Boolean
  ) {
    getOffers(
      asin: $asin
      page: $page
      isConditionNew: $isConditionNew
      isConditionUsedLikeNew: $isConditionUsedLikeNew
      isConditionUsedVeryGood: $isConditionUsedVeryGood
      isConditionUsedGood: $isConditionUsedGood
      isConditionUsedAcceptable: $isConditionUsedAcceptable
      isPrime: $isPrime
      isFreeShipping: $isFreeShipping
    )
  }
`;
export const getSeller = /* GraphQL */ `
  query GetSeller($sellerId: String, $isDetail: Boolean, $domain: String) {
    getSeller(sellerId: $sellerId, isDetail: $isDetail, domain: $domain)
  }
`;
export const getCategories = /* GraphQL */ `
  query GetCategories(
    $categoryId: String!
    $includeParents: Boolean
    $domain: String
  ) {
    getCategories(
      categoryId: $categoryId
      includeParents: $includeParents
      domain: $domain
    )
  }
`;
export const queryProduct = /* GraphQL */ `
  query QueryProduct(
    $queryJSON: AWSJSON!
    $domain: String
    $isAll: Boolean
    $updateLimit: Boolean
  ) {
    queryProduct(
      queryJSON: $queryJSON
      domain: $domain
      isAll: $isAll
      updateLimit: $updateLimit
    )
  }
`;
export const getCategoryRanking = /* GraphQL */ `
  query GetCategoryRanking(
    $categoryId: String!
    $range: Int!
    $limit: Int
    $domain: String
  ) {
    getCategoryRanking(
      categoryId: $categoryId
      range: $range
      limit: $limit
      domain: $domain
    )
  }
`;
export const getTracking = /* GraphQL */ `
  query GetTracking(
    $asin: String
    $countPerPage: Int
    $page: Int
    $domain: String
  ) {
    getTracking(
      asin: $asin
      countPerPage: $countPerPage
      page: $page
      domain: $domain
    )
  }
`;
export const getStripeBillingPortal = /* GraphQL */ `
  query GetStripeBillingPortal(
    $returnUrl: String!
    $priceId: String
    $successUrl: String
    $stripeDomain: String
  ) {
    getStripeBillingPortal(
      returnUrl: $returnUrl
      priceId: $priceId
      successUrl: $successUrl
      stripeDomain: $stripeDomain
    )
  }
`;
export const exchangeRate = /* GraphQL */ `
  query ExchangeRate($currency: String!) {
    exchangeRate(currency: $currency)
  }
`;
export const sellerProducts = /* GraphQL */ `
  query SellerProducts($domain: String!, $sellerId: String!, $page: Int!) {
    sellerProducts(domain: $domain, sellerId: $sellerId, page: $page)
  }
`;
export const isSubscriber = /* GraphQL */ `
  query IsSubscriber($includeAdmin: Boolean) {
    isSubscriber(includeAdmin: $includeAdmin)
  }
`;
export const getSubscriberType = /* GraphQL */ `
  query GetSubscriberType($includeAdmin: Boolean) {
    getSubscriberType(includeAdmin: $includeAdmin)
  }
`;
export const codeSearch = /* GraphQL */ `
  query CodeSearch($code: String, $domain: String, $saveHistory: Boolean) {
    codeSearch(code: $code, domain: $domain, saveHistory: $saveHistory)
  }
`;
export const isAdmin = /* GraphQL */ `
  query IsAdmin {
    isAdmin
  }
`;
export const adminListUsers = /* GraphQL */ `
  query AdminListUsers($page: Int!, $perPage: Int, $email: String) {
    adminListUsers(page: $page, perPage: $perPage, email: $email)
  }
`;
export const getReviews = /* GraphQL */ `
  query GetReviews(
    $domain: String!
    $asin: String!
    $rating: Int
    $page: Int!
  ) {
    getReviews(domain: $domain, asin: $asin, rating: $rating, page: $page)
  }
`;
export const keywordMiner = /* GraphQL */ `
  query KeywordMiner(
    $domain: String!
    $keyword: String!
    $option: AWSJSON!
    $batchCount: Int
  ) {
    keywordMiner(
      domain: $domain
      keyword: $keyword
      option: $option
      batchCount: $batchCount
    )
  }
`;
export const getDplusLink = /* GraphQL */ `
  query GetDplusLink {
    getDplusLink
  }
`;
export const ConvertToASIN = /* GraphQL */ `
  query ConvertToASIN($title: String!) {
    ConvertToASIN(title: $title)
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($asin: String!) {
    getProduct(asin: $asin) {
      asin
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $asin: String
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProducts(
      asin: $asin
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        asin
        json
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductLiteCache = /* GraphQL */ `
  query GetProductLiteCache($asin: String!) {
    getProductLiteCache(asin: $asin) {
      asin
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductLiteCaches = /* GraphQL */ `
  query ListProductLiteCaches(
    $asin: String
    $filter: ModelProductLiteCacheFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductLiteCaches(
      asin: $asin
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        asin
        json
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategoryCache = /* GraphQL */ `
  query GetCategoryCache($categoryId: String!, $domain: Int!) {
    getCategoryCache(categoryId: $categoryId, domain: $domain) {
      categoryId
      domain
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCategoryCaches = /* GraphQL */ `
  query ListCategoryCaches(
    $categoryId: String
    $domain: ModelIntKeyConditionInput
    $filter: ModelCategoryCacheFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategoryCaches(
      categoryId: $categoryId
      domain: $domain
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        categoryId
        domain
        json
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategoryRankingRange = /* GraphQL */ `
  query GetCategoryRankingRange($categoryId: String!, $range: Int!) {
    getCategoryRankingRange(categoryId: $categoryId, range: $range) {
      categoryId
      range
      domain
      asins
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCategoryRankingRanges = /* GraphQL */ `
  query ListCategoryRankingRanges(
    $categoryId: String
    $range: ModelIntKeyConditionInput
    $filter: ModelCategoryRankingRangeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategoryRankingRanges(
      categoryId: $categoryId
      range: $range
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        categoryId
        range
        domain
        asins
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategoryRankingArchive = /* GraphQL */ `
  query GetCategoryRankingArchive($id: ID!) {
    getCategoryRankingArchive(id: $id) {
      id
      categoryId
      domain
      month
      asins
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCategoryRankingArchives = /* GraphQL */ `
  query ListCategoryRankingArchives(
    $filter: ModelCategoryRankingArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoryRankingArchives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        domain
        month
        asins
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategoryRankingRegisteredMonths = /* GraphQL */ `
  query GetCategoryRankingRegisteredMonths($categoryId: String!) {
    getCategoryRankingRegisteredMonths(categoryId: $categoryId) {
      categoryId
      registeredMonths {
        archiveId
        month
        domain
        createdAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCategoryRankingRegisteredMonthss = /* GraphQL */ `
  query ListCategoryRankingRegisteredMonthss(
    $categoryId: String
    $filter: ModelCategoryRankingRegisteredMonthsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategoryRankingRegisteredMonthss(
      categoryId: $categoryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        categoryId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWarningBrands = /* GraphQL */ `
  query GetWarningBrands($id: ID!) {
    getWarningBrands(id: $id) {
      id
      brands
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWarningBrandss = /* GraphQL */ `
  query ListWarningBrandss(
    $filter: ModelWarningBrandsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWarningBrandss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brands
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWarningItem = /* GraphQL */ `
  query GetWarningItem($key: String!) {
    getWarningItem(key: $key) {
      key
      domain
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWarningItems = /* GraphQL */ `
  query ListWarningItems(
    $key: String
    $filter: ModelWarningItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWarningItems(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        domain
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplicationSettings = /* GraphQL */ `
  query GetApplicationSettings($key: String!) {
    getApplicationSettings(key: $key) {
      key
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApplicationSettingss = /* GraphQL */ `
  query ListApplicationSettingss(
    $key: String
    $filter: ModelApplicationSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApplicationSettingss(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        value
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFavorite = /* GraphQL */ `
  query GetFavorite($owner: String!) {
    getFavorite(owner: $owner) {
      owner
      asins {
        asin
        domain
        title
        memo
        date
        __typename
      }
      historyAsins {
        asin
        domain
        date
        __typename
      }
      browsingAsins {
        asin
        domain
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFavorites = /* GraphQL */ `
  query ListFavorites(
    $owner: String
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFavorites(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFavoriteSeller = /* GraphQL */ `
  query GetFavoriteSeller($owner: String!) {
    getFavoriteSeller(owner: $owner) {
      owner
      sellers {
        id
        domain
        displayName
        memo
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFavoriteSellers = /* GraphQL */ `
  query ListFavoriteSellers(
    $owner: String
    $filter: ModelFavoriteSellerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFavoriteSellers(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($owner: String!) {
    getUserInfo(owner: $owner) {
      owner
      registered
      name
      organization
      membershipType
      stripeCustomer
      stripeDomain
      appStoreTransactionId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserInfos = /* GraphQL */ `
  query ListUserInfos(
    $owner: String
    $filter: ModelUserInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserInfos(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        registered
        name
        organization
        membershipType
        stripeCustomer
        stripeDomain
        appStoreTransactionId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStripeInfo = /* GraphQL */ `
  query GetStripeInfo($customer: String!) {
    getStripeInfo(customer: $customer) {
      customer
      username
      subscription
      periodEnd
      planId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStripeInfos = /* GraphQL */ `
  query ListStripeInfos(
    $customer: String
    $filter: ModelStripeInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStripeInfos(
      customer: $customer
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        customer
        username
        subscription
        periodEnd
        planId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStripeInfoCom = /* GraphQL */ `
  query GetStripeInfoCom($customer: String!) {
    getStripeInfoCom(customer: $customer) {
      customer
      username
      subscription
      periodEnd
      planId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStripeInfoComs = /* GraphQL */ `
  query ListStripeInfoComs(
    $customer: String
    $filter: ModelStripeInfoComFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStripeInfoComs(
      customer: $customer
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        customer
        username
        subscription
        periodEnd
        planId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAppStoreInfo = /* GraphQL */ `
  query GetAppStoreInfo($originalTransactionId: String!) {
    getAppStoreInfo(originalTransactionId: $originalTransactionId) {
      originalTransactionId
      username
      active
      notificationUUID
      notificationType
      subtype
      expiresDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAppStoreInfos = /* GraphQL */ `
  query ListAppStoreInfos(
    $originalTransactionId: String
    $filter: ModelAppStoreInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppStoreInfos(
      originalTransactionId: $originalTransactionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        originalTransactionId
        username
        active
        notificationUUID
        notificationType
        subtype
        expiresDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAppStoreTransactionLog = /* GraphQL */ `
  query GetAppStoreTransactionLog(
    $originalTransactionId: String!
    $notificationUUID: String!
  ) {
    getAppStoreTransactionLog(
      originalTransactionId: $originalTransactionId
      notificationUUID: $notificationUUID
    ) {
      originalTransactionId
      notificationUUID
      notificationType
      subtype
      expiresDate
      transactionData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAppStoreTransactionLogs = /* GraphQL */ `
  query ListAppStoreTransactionLogs(
    $originalTransactionId: String
    $notificationUUID: ModelStringKeyConditionInput
    $filter: ModelAppStoreTransactionLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppStoreTransactionLogs(
      originalTransactionId: $originalTransactionId
      notificationUUID: $notificationUUID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        originalTransactionId
        notificationUUID
        notificationType
        subtype
        expiresDate
        transactionData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserSettings = /* GraphQL */ `
  query GetUserSettings($owner: String!) {
    getUserSettings(owner: $owner) {
      owner
      settings {
        key
        value
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserSettingss = /* GraphQL */ `
  query ListUserSettingss(
    $owner: String
    $filter: ModelUserSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserSettingss(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSearchConditions = /* GraphQL */ `
  query GetSearchConditions($owner: String!) {
    getSearchConditions(owner: $owner) {
      owner
      conditions {
        id
        name
        condition
        domain
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSearchConditionss = /* GraphQL */ `
  query ListSearchConditionss(
    $owner: String
    $filter: ModelSearchConditionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSearchConditionss(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductMeta = /* GraphQL */ `
  query GetProductMeta($asin: String!, $domain: Int!) {
    getProductMeta(asin: $asin, domain: $domain) {
      asin
      domain
      pageview {
        count
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductMetas = /* GraphQL */ `
  query ListProductMetas(
    $asin: String
    $domain: ModelIntKeyConditionInput
    $filter: ModelProductMetaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductMetas(
      asin: $asin
      domain: $domain
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        asin
        domain
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSharedSearchCondition = /* GraphQL */ `
  query GetSharedSearchCondition($id: String!) {
    getSharedSearchCondition(id: $id) {
      id
      username
      conditionId
      name
      condition
      domain
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSharedSearchConditions = /* GraphQL */ `
  query ListSharedSearchConditions(
    $id: String
    $filter: ModelSharedSearchConditionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSharedSearchConditions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        username
        conditionId
        name
        condition
        domain
        date
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
