export default {
  "売上個数予測": "Sales Estimator",
  "ベータ版機能テスト中": "Beta functionality is being tested",
  "カテゴリー": "Category",
  "予測ランキング": "Ranking",
  "※予測ランキングは1～10000の範囲を指定できます。": "*Predictive ranking can range from 1 to 10000.",
  "検索": "Search",
  "週間売上個数": "Weekly unit sales",
  "月間売上個数": "Monthly unit sales",
  "すべて": "All",
}
