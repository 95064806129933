export default {
  "アカウント情報": "Account Information",
  "管理用ユーザーでログインしています。": "You are logged in as an administrative user.",
  "会員種別：ERESA PRO会員（有料会員）": "Membership type: ERESA PRO member (paid membership)",
  "会員種別：ERESA PRO会員（アプリ版から課金された有料会員）": "Membership type: ERESA PRO member (paid membership charged from the app version)",
  "会員種別：無料会員": "Membership type: Free membership",
  "プランの確認及び変更": "Confirmation and modification of plans",
  "ERESA PRO版（月額プラン）": "Join ERESA PRO version(Monthly Plan)",
  "ERESA PRO版（年額プラン）※2ヶ月間無料！": "Join ERESA PRO version(Annual Plan) *Free for 2 months!",
  "ERESA拡張機能アクセストークン": "ERESA extensions access token",
  "※chrome拡張機能の一部機能の利用に必要なアクセストークン（毎月更新）です。": "*Access token (updated monthly) required to use some of the features of the chrome extension.",
  "コピーされました": "Copied.",
  "組織種別": "Type of organization",
  "変更": "Change",
  "退会が無事完了しました。またのご利用をお待ちしております。": "Your withdrawal has been successfully completed. We look forward to serving you again.",
  "※有料プラン加入の場合、同時に解約となっております。": "* If you have subscribed to a paid plan, it was cancelled at the same time.",
  "メールアドレス": "Email address",
  "会員情報を削除する": "Delete membership information",
  "退会すると全てのデータが削除され、メルマガも解除されます。<br />退会しますか？": "When you cancel your membership, all data will be deleted and the newsletter will be cancelled.<br/>Do you wish to cancel your membership?",
  "【ご注意】課金の解除はアプリからも必ず行ってください。(こちらの退会だけでは課金は止まりません。)": "Please note: Please be sure to cancel your billing through the application as well. (Billing will not stop just by canceling your membership here.)",
  "退会確認": "Withdrawal confirmation",
  "お知らせの表示": "Display of Notices",
  "お知らせの表示を選択してください。": "Select the display of notices.",
  "：": ":",
}
