import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import RegisterAccount from '../views/register/RegisterAccount.vue'
import Search from '../views/Search.vue'
import SearchLite from '../views/SearchLite.vue'
import CodeSearch from '../views/CodeSearch.vue'
import TitleSearch from '../views/TitleSearch.vue'
import SeoResearch from '../views/SeoResearch.vue'
import SellerResearch from '../views/SellerResearch.vue'
import ReviewResearch from '../views/ReviewResearch.vue'
import KeywordMining from '../views/KeywordMining.vue'
import CategoryRanking from '../views/CategoryRanking.vue'
import CategoryRankingPro from '../views/CategoryRankingPro.vue'
import SalesEstimation from '../views/SalesEstimation.vue'
import Favorites from '../views/Favorites.vue'
import Tracking from '../views/Tracking.vue'
import TrackingDetail from '../views/TrackingDetail.vue'
import Offers from "@/views/Offers.vue"
import Detail from '../views/Detail.vue'
import ChangeMailAddress from '@/views/ChangeMailAddress.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import Demo from '../views/Demo.vue'
import store from '../store'
import AppDetail from '../views/applink/AppDetail.vue'
import AppSearch from '../views/applink/AppSearch.vue'
import AppFavorites from '../views/applink/AppFavorites.vue'
import Archive from '../views/admin/Archive.vue'
import Warning from '../views/admin/Warning.vue'

import { AmplifyEventBus } from 'aws-amplify-vue'
import * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'

Vue.use(VueRouter)
Vue.use(AmplifyPlugin, AmplifyModules)


let user;

// ユーザー管理
getUser().then(/*(user) => {
  if (user) {
    router.push({ path: '/' });
  }
}*/);

function getUser() {
  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('setUser', data);
      return data;
    }
  }).catch(() => {
    store.commit('setUser', null);
    return null;
  });
}

async function getGroups() {
  return await Vue.prototype.$Amplify.Auth.currentSession().then(user => {
    const { payload } = user.getIdToken()
    if(payload != void 0 && payload['cognito:groups']) {
      const groups = payload['cognito:groups'];
      if (groups != void 0) {
        return groups;
      }
    }
    return [];
  });
}

// ログイン状態管理
AmplifyEventBus.$on('authState', async (state) => {
  if (state === 'signedOut') {
    user = null;
    store.commit('setUser', null);
    (await store.getters.getFavorites).clearCache();
    store.commit('setFavorites', null);
    router.push({ path: '/login' });
  } else if (state === 'signedIn') {
    user = await getUser();
    (await store.getters.getFavorites).clearCache();
    store.commit('setFavorites', null);
    router.push({ path: '/' });
  }
});

const routes = [
  {
    // ログインページ
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    // アカウント登録ページ
    path: '/register',
    name: 'Register',
    component: RegisterAccount
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: () => import('../views/register/ResetPassword.vue')
  },
  {
    path: '/resetPasswordSubmit',
    name: 'ResetPasswordSubmit',
    component: () => import('../views/register/ResetPasswordSubmit.vue')
  },
  {
    path: '/confirmSignUp',
    name: 'ConfirmSignUp',
    component: () => import('../views/register/ConfirmSignUp.vue')
  },
  {
    path: '/',
    name: 'Default',
    component: Search,
    meta: { requiresAuth: false }
  },
  {
    path: '/search/:words',
    name: 'Search',
    component: Search,
    meta: { requiresAuth: false }
  },
  {
    path: '/searchLite',
    name: 'SearchLite',
    component: SearchLite,
    meta: { requiresAuth: true }
  },
  {
    path: '/searchLite/:words',
    name: 'SearchLite',
    component: SearchLite,
    meta: { requiresAuth: true }
  },
  {
    path: '/codeSearch',
    name: 'CodeSearch',
    component: CodeSearch,
    meta: { requiresAuth: true }
  },
  {
    path: '/codeSearch/:words',
    name: 'CodeSearch',
    component: CodeSearch,
    meta: { requiresAuth: true }
  },
  {
    path: '/titleSearch',
    name: 'TitleSearch',
    component: TitleSearch,
    meta: { requiresAuth: true }
  },
  {
    path: '/titleSearch/:words',
    name: 'TitleSearch',
    component: TitleSearch,
    meta: { requiresAuth: true }
  },
  {
    path: '/seoResearch',
    name: 'SeoResearch',
    component: SeoResearch,
    meta: { requiresAuth: true }
  },
  {
    path: '/seoResearch/:words',
    name: 'SeoResearch',
    component: SeoResearch,
    meta: { requiresAuth: true }
  },
  {
    path: '/sellerResearch',
    name: 'SellerResearchDefault',
    component: SellerResearch,
    meta: { requiresAuth: true }
  },
  {
    path: '/sellerResearch/:sellerid',
    name: 'SellerResearch',
    component: SellerResearch,
    meta: { requiresAuth: true }
  },
  {
    path: '/reviewResearch',
    name: 'ReviewResearch',
    component: ReviewResearch,
    meta: { requiresAuth: true }
  },
  {
    path: '/reviewResearch/:asin',
    name: 'ReviewResearch',
    component: ReviewResearch,
    meta: { requiresAuth: true }
  },
  {
    path: '/keywordMining',
    name: 'KeywordMining',
    component: KeywordMining,
    meta: { requiresAuth: true }
  },
  {
    path: '/keywordMining/:words',
    name: 'KeywordMining',
    component: KeywordMining,
    meta: { requiresAuth: true }
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: Favorites,
    meta: { requiresAuth: true }
  },
  {
    path: '/tracking',
    name: 'TrackingList',
    component: Tracking,
    meta: { requiresAuth: true }
  },
  {
    path: '/tracking/:asin',
    name: 'Tracking',
    component: TrackingDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/offers/:asin',
    name: 'Offers',
    component: Offers,
    meta: { requiresAuth: true }
  },
  {
    path: '/categoryRanking',
    name: 'CategoryRankingDefault',
    component: CategoryRanking,
    meta: { requiresAuth: true }
  },
  {
    path: '/categoryRanking/:categoryId',
    name: 'CategoryRanking',
    component: CategoryRanking,
    meta: { requiresAuth: true }
  },
  {
    path: '/categoryRankingPro',
    name: 'CategoryRankingPro',
    component: CategoryRankingPro,
    meta: { requiresAuth: true }
  },
  {
    path: '/salesEstimation',
    name: 'SalesEstimation',
    component: SalesEstimation,
    meta: { requiresAuth: true }
  },
  {
    path: '/detail/:asin',
    name: 'Detail',
    component: Detail,
    meta: { requiresAuth: true }
  },
  {
    path: '/account',
    name: 'Account',
    meta: { requiresAuth: true },
    component: () => import('../views/Account.vue')
  },
  {
    path: '/affiliate',
    name: 'Affiliate',
    meta: { requiresAuth: true },
    component: () => import('../views/Affiliate.vue')
  },
  {
    path: '/aiAdvisor',
    name: 'AiAdvisor',
    meta: { requiresAuth: true },
    component: () => import('../views/AiAdvisor.vue')
  },
  {
    path: '/changeMailAddress',
    name: 'ChangeMailAddress',
    component: ChangeMailAddress,
    meta: { requiresAuth: true },
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { requiresAuth: true },
  },
  {
    path: '/demo',
    name: 'Demo',
    component: Demo,
    meta: { requiresAuth: false }
  },
  {
    path: '/applink/register',
    name: 'AppRegister',
    component: RegisterAccount
  },
  {
    path: '/applink/detail',
    name: 'AppDetail',
    component: AppDetail,
  },
  {
    path: '/applink/search',
    name: 'AppSearch',
    component: AppSearch,
  },
  {
    path: '/applink/favorites',
    name: 'AppFavorites',
    component: AppFavorites,
  },
  {
    path: '/settings',
    name: 'Setting',
    meta: { requiresAuth: true },
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/admin/archive',
    name: 'Archive',
    component: Archive,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/warning',
    name: 'Warning',
    component: Warning,
    meta: { requiresAuth: true, requiresAdmin: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// リダイレクト設定
router.beforeResolve(async (to, from, next) => {
  const refreshToken = async () => {
    const cognitoUser = await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser != void 0) {
      const currentSession = await Vue.prototype.$Amplify.Auth.currentSession();
      await cognitoUser.refreshSession(currentSession.refreshToken, () => {});
    }
  };

  document.body.style.backgroundColor = '#f2f2f2';

  if (to.matched.some(record => record.meta.requiresAdmin)) {
    user = await getUser();
    //console.log(user)
    if (!user) {
      return next({
        path: '/login'
      });
    }
    const groups = await getGroups();
    if (!groups.includes('EresaAdmin')) {
      await refreshToken();
      return next({
        path: '/'
      });
    }
    return next();
  }
  else if (to.matched.some(record => record.meta.requiresAuth)) {
    user = await getUser();
    //console.log(user)
    if (!user) {
      return next({
        path: '/login'
      });
    }
    return next();
  }
  return next();
});

export default router
