<template>
  <div class="list">
    <template v-for="(item, i) in list">
      <div :class="i > 0 ? 'mt-3 pt-3 border-top' : ''" :key="`content-${i}`">
        <div class="d-flex">
          <b-form-rating :value="item.rating" color="orange" class="rating" readonly="true" size="sm"></b-form-rating>
          <div class="title">{{ item.title }}</div>
        </div>
        <div class="text-secondary">{{ item.date }}</div>

        <div v-if="item.show" class="mt-2">{{ item.body }}</div>
        <div v-else class="mt-2">
          {{ item.body.substr(0, 200) }}
          <b-button v-if="item.body.length > 200" variant="link" class="readmore" @click="item.show = true">{{ t('…続きを読む▼') }}</b-button>
        </div>

      </div>
    </template>
  </div>
</template>

<script>
import Utils from '@/mixins/utils';

export default {
  name: 'ReviewList',
  props: ['reviews', 'domainId'],
  data: function () {
    return {
      list: [],
    };
  },
  mixins: [Utils],
  async mounted() {
    if (this.reviews == void 0) {
      return;
    }
    this.list.push(...this.reviews.map(item => ({ title: item.title, body: item.body, rating: item.rating, date: item.date.raw ?? '', show: false })));
    this.$emit('loaded');
  },
  methods: {
    createTitleData() {
      return this.list.map(item => item.title);
    },
  }
};
</script>

<style scoped>
.list {
  font-size: 10pt;
}

.rating {
  width: 110px;
  padding: 0px;
  border-width: 0px;
  background-color: transparent;
}

.title {
  font-weight: bold;
  margin-top: 6px;
  margin-left: 6px;
}

.readmore {
  font-size: 10pt;
  padding: 0px;
}
</style>
