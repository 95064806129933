export default {
  "キーワードマイニング": "Keyword Mining",
  "ベータ版機能テスト中": "Beta functionality is being tested",
  "こちらの機能は現在、パソコンでのみご利用いただけます。": "This feature is currently only available on PCs.",
  "過去30日": "Last 30 days",
  "キーワード": "Keyword",
  "※こちらの機能はSellerSprite（セラースプライト）社とのAPI連携により実現しております。": "This functionality is made possible through API integration with SellerSprite.",
  "カテゴリー": "Category",
  "月間検索数": "Monthly searches",
  "日平均検索数": "Average daily searches",
  "月間販売数": "Monthly sales",
  "転換率": "Conversion rate",
  "SPR": "SPR",
  "タイトル密度": "Title density",
  "商品数": "Number of products",
  "需給比": "Supply-demand ratio",
  "広告ライバル商品数": "Number of advertised competitors' products",
  "クリック集中度": "Click concentration",
  "転換総数比率": "Ratio of total number of conversions",
  "PPC入札額": "PPC bid amount",
  "市場分析": "Market analysis",
  "星評価": "Rating",
  "検索": "Search",
  "CSV出力": "CSV output",
  "CSV一括出力": "CSV batch output",
  "{0}の検索に一致する結果はありませんでした。": "No results matching your search for {0} were found.",
  "検索が失敗しました。再度検索を試してください。※アクセスが集中するピーク時間帯には検索が失敗する場合がありますので、何度か試みても問題が解決されない場合は、時間を空けてから再度お試しください。": "Search failed. Please try the search again. *Searches may fail during peak hours when access is concentrated, so if the problem persists after several attempts, please give it some time and try again.",
  "keywordMiningCsvheader": "Keyword,Amazon Choice,Category,Monthly searches,Average daily searches,Monthly sales,Conversion rate,SPR,Title density,Number of products,Supply-demand ratio,Number of advertised competitors' products,Click concentration,Ratio of total number of conversions,PPC bid amount,PPC bid amount(From),PPC bid amount(To),Market analysis,Rating",
}
