<template>
  <div class="page-content">
    <div class="container">
      <manual-link :href="manuallink">{{ t('キーワードマイニング') }}</manual-link>
      <b-alert class="d-none d-md-block" show variant="warning">{{ t('ベータ版機能テスト中') }}</b-alert>
      <b-alert class="d-md-none" show variant="warning">{{ t('こちらの機能は現在、パソコンでのみご利用いただけます。') }}</b-alert>

      <!-- PC -->
      <div class="d-none d-md-block">
        <div class="max-w-md d-flex flex-column m-2">
          <div class="search input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <svg width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"></path>
                  <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"></path>
                </svg>
              </span>
            </div>
            <b-dropdown :text="historyDate.name" class="input-group-append dropdown-search" size="sm">
              <b-dropdown-item v-for="(item, i) in historyDateList" :key="`historyDate-${i}`" @click="historyDateSelected(item)">
                {{ item.name }}
              </b-dropdown-item>
            </b-dropdown>
            <input
              type="text"
              class="form-control py-4"
              :placeholder="t('キーワード')"
              :aria-label="t('キーワード')"
              aria-describedby="search-product"
              v-model="searchWords"
              @keydown.enter="search"
              @keyup="searchKeyup"
              @blur="searchBlur"
            />
            <div class="input-group-append">
              <b-button class="btn bg-eresa text-white px-3" type="button" @click="search">{{ t('検索') }}</b-button>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="d-md-none">
        <!--<div class="d-flex flex-wrap m-2">
          <div class="w-100 d-flex flex-column">
            <div class="search input-group">
              <input
                ref="search"
                type="text"
                class="form-control py-4"
                :placeholder="t('キーワード')"
                :aria-label="t('キーワード')"
                aria-describedby="search-product"
                v-model="searchWords"
                @keydown.enter="search"
                @keyup="searchKeyup"
                @blur="searchBlur"
              />
              <b-dropdown :text="historyDate.name" class="input-group-append dropdown-search" size="sm">
                <b-dropdown-item v-for="(item, i) in historyDateList" :key="`historyDate-${i}`" class="small" @click="historyDateSelected(item)">
                  {{ item.name }}
                </b-dropdown-item>
              </b-dropdown>
              <div class="input-group-append">
                <b-button
                  class="btn bg-eresa text-white px-3"
                  type="button"
                  @click="search"
                  id="search-product"
                  ><font-awesome-icon :icon="['fas', 'magnifying-glass']" style="font-size:24px" />
                </b-button>
              </div>
            </div>
          </div>
        </div>
        -->
      </div>
      <candidate-window ref="candidateList" @selected="selectedKeyword" />

      <span class="text-secondary small ml-2 d-none d-sm-block">{{ t('※こちらの機能はSellerSprite（セラースプライト）社とのAPI連携により実現しております。') }}</span>
    </div>

    <div class="d-none d-md-block">

    <b-alert :show="showMessege && messageType === MESSAGE_NOTFOND" variant="info">{{ t('{0}の検索に一致する結果はありませんでした。', [lastSearchWords]) }}</b-alert>
    <b-alert :show="showMessege && messageType === MESSAGE_TIMEOUT" variant="info">{{ t('検索が失敗しました。再度検索を試してください。※アクセスが集中するピーク時間帯には検索が失敗する場合がありますので、何度か試みても問題が解決されない場合は、時間を空けてから再度お試しください。') }}</b-alert>

    <div v-if="items.length > 0" class="container mt-4">
      <h6>{{ t('{0}件中 {1} ～ {2}件を表示', [pagination.count, pagination.start + 1, pagination.end]) }}</h6>
      <div class="pagepanel d-flex d-sm-none flex-wrap">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa" size="sm"></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
        <div style="margin: 0 0 0 auto">
          <b-button class="btn downloadcsv text-white" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
          <b-button class="btn downloadcsv text-white ml-2" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadBatchCsv">{{ t('CSV一括出力') }}</b-button>
        </div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa"></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
        <div style="margin: 0 0 0 auto">
          <b-button class="btn downloadcsv text-white" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadCsv">{{ t('CSV出力') }}</b-button>
          <b-button class="btn downloadcsv text-white ml-2" :disabled="disabledDownloadCsv ? disabledDownloadCsv : void 0" @click="downloadBatchCsv">{{ t('CSV一括出力') }}</b-button>
        </div>
      </div>
    </div>

    <div v-if="items.length > 0" class="container mb-5 list list-table">
      <div class="list-title">No</div>
      <div class="list-title">{{ t('キーワード') }}</div>
      <div class="list-title">{{ t('カテゴリー') }}</div>
      <div class="list-title">{{ t('月間検索数') }}<br/>{{ t('日平均検索数') }}</div>
      <div class="list-title">{{ t('月間販売数') }}<br/>{{ t('転換率') }}</div>
      <div class="list-title">{{ t('SPR') }}</div>
      <div class="list-title">{{ t('タイトル密度') }}</div>
      <div class="list-title">{{ t('商品数') }}</div>
      <div class="list-title">{{ t('需給比') }}</div>
      <div class="list-title">{{ t('広告ライバル商品数') }}</div>
      <div class="list-title">{{ t('クリック集中度') }}<br/>{{ t('転換総数比率') }}</div>
      <div class="list-title">{{ t('PPC入札額') }}</div>
      <div class="list-title">{{ t('市場分析') }}<br/>{{ t('星評価') }}</div>
      <template v-for="(item, i) in items">
        <div class="list-detail" :key="`no-${i}`">{{ i + 1 + pagination.start }}</div>
        <div class="list-detail" :key="`keyword-${i}`">
          {{ item.keyword }}<span v-if="item.amazonChoice === true" class="small p-1 text-white ml-1 tag-amazons-choice">AC</span>
        </div>
        <div class="list-detail" :key="`departments-${i}`">
          <div v-for="d in item.departments" class="d-flex flex-row" :key="`departments-${i}-${d.code}`">{{ d.label }}</div>
        </div>
        <div class="list-detail text-right" :key="`searches-${i}`">
          <div>{{ item.searches | number }}</div>
          <div>{{ item.searches == void 0 || item.searches == 0 ? 0 : parseInt(item.searches / 30) | number }}</div>
        </div>
        <div class="list-detail text-right" :key="`purchases-${i}`">
          <div>{{ item.purchases | number }}</div>
          <div>{{ item.searches == void 0 || item.searches == 0 ? 0 : item.purchases / item.searches | rate }}</div>
        </div>
        <div class="list-detail text-right" :key="`spr-${i}`">{{ item.spr | number }}</div>
        <div class="list-detail text-right" :key="`titleDensity-${i}`">{{ item.titleDensity | number }}</div>
        <div class="list-detail text-right" :key="`products-${i}`">{{ item.products | number }}</div>
        <div class="list-detail text-right" :key="`supply-demand -${i}`">
          {{ item.products == void 0 || item.products == 0 ? 0 : item.searches / item.products | round }}
        </div>
        <div class="list-detail text-right" :key="`adProducts-${i}`">{{ item.adProducts | number }}</div>
        <div class="list-detail text-right" :key="`monopolyClickRate-${i}`">
          <div>{{ item.monopolyClickRate | rate }}</div>
          <div>{{ item.cvsShareRate | rate }}</div>
        </div>
        <div class="list-detail text-right" :key="`bid-${i}`">
          <div>{{ price(item, item.bid) }}</div>
          <div>{{ price(item, item.bidMin) }} - {{ price(item, item.bidMax) }}</div>
        </div>
        <div class="list-detail text-right" :key="`avgPrice-${i}`">
          <div>{{ price(item, item.avgPrice) }}</div>
          <div>{{ item.avgRating }}</div>
        </div>
      </template>
    </div>

    <div v-if="items.length > 0" class="container">
      <div class="pagepanel d-flex d-sm-none flex-wrap">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa" size="sm"></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="pagination.count" :per-page="pagination.countPerPage" class="pagination-eresa"></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, pagination.lastpage]) }}</div>
      </div>
      <h6>{{ t('{0}件中 {1} ～ {2}件を表示', [pagination.count, pagination.start + 1, pagination.end]) }}</h6>
    </div>

    <Loading v-else-if="loading"></Loading>
    </div>
    <loading-screen v-if="isProcessing"></loading-screen>
  </div>
</template>

<script>
import moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import ManualLink from '@/components/ManualLink.vue';
import Loading from '@/components/Loading.vue';
import LoadingScreen from "@/components/LoadingScreen.vue";
import CandidateWindow from '@/components/CandidateWindow.vue';
import Utils from '@/mixins/utils';
import AuthUtil from '@/mixins/authutil';

const MANUAL_JP = 'https://pro.eresa.jp/function/keyword-mining/';
const MANUAL_COM = 'https://eresa.io/function/keyword-mining/';

const DEFAULT_ROWS = 200;
const MESSAGE_NOTFOND = 0;
const MESSAGE_TIMEOUT = 1;
const MARKETPLACE_JP = 'JP';

export default {
  name: 'KeywordMining',
  components: {
    ManualLink,
    Loading,
    LoadingScreen,
    CandidateWindow,
  },
  mixins: [Utils, AuthUtil],
  filters: {
    number(val) {
      return val != void 0 && val > 0 ? val.toLocaleString() : '-';
    },
    rate(val) {
      if (val == void 0 || val <= 0) {
        return '-';
      }
      const opt = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
      return `${(Math.round(val * 10000) / 100).toLocaleString(undefined, opt)}%`;
    },
    round(val) {
      if (val == void 0 || val <= 0) {
        return '-';
      }
      const opt = { minimumFractionDigits: 1, maximumFractionDigits: 1 };
      return (Math.round(val * 10) / 10).toLocaleString(undefined, opt);
    },
  },
  data() {
    return {
      MESSAGE_NOTFOND,
      MESSAGE_TIMEOUT,
      historyDateList: [{name: this.t('過去30日')}, {name: '2023-07'}],
      historyDate: {name: this.t('過去30日')},
      searchWords: null,
      items: [],
      page: this.$route.query.page ?? 1,
      pagination: {
        count: 0,
        countPerPage: DEFAULT_ROWS,
        lastpage: 1,
        start: 0,
        end: 0,
      },
      disabledDownloadCsv: true, // CSV出力非活性フラグ
      showMessege: false,
      messageType: MESSAGE_NOTFOND,
      resultListKey: 0,
      loading: false,
      isProcessing: false,
      lastSearchHistoryDate: null,
      lastSearchWords: null,
      lastSearchDomain: null,
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
    }
  },
  async created() {
    if (this.$route.query.domain != void 0) {
      const isCom = this.isComDomain(this.$route.query.domain);
      this.$store.commit("setDomainCom", isCom);
    }
    this.searchWords = this.$route.params.words;
  },
  async mounted() {
    this.initializeHistoryDateList();
    if (this.searchWords != void 0 && this.searchWords !== '') {
      this.search();
    }
    await this.validateSubscriber();
    await this.validateSubscriberYear();
  },
  computed: {},
  watch: {
    async page() {
      if (this.lastSearchDomain != void 0) {
        this.$store.commit("setDomain", this.lastSearchDomain);
      }
      this.historyDate = this.lastSearchHistoryDate;
      this.searchWords = this.lastSearchWords;
      await this.showList();
    },
  },
  methods: {
    async search() {
      if (this.searchWords == void 0 || this.searchWords === '') {
        console.info("input empty");
        return;
      }

      this.lastSearchHistoryDate = this.historyDate;
      this.lastSearchWords = this.searchWords;
      this.lastSearchDomain = this.$store.getters.getDomain;
      this.page = 1;
      this.resultListKey++;
      await this.showList();
    },
    async showList() {
      if (this.loading) {
        return;
      }
      this.showMessege = false;
      this.messageType = MESSAGE_NOTFOND;
      try {
        this.disabledDownloadCsv = true;
        this.loading = true;
        this.items = [];
        const option = { page: this.page, size: this.pagination.countPerPage, historyDate: this.lastSearchHistoryDate.value };
        const rslt = await API.graphql(
          graphqlOperation(queries.keywordMiner, { domain: this.lastSearchDomain, keyword: this.lastSearchWords, option: JSON.stringify(option) })
        );
        if (rslt.data.keywordMiner == void 0) {
          this.showMessege = true;
          return;
        }
        const body = JSON.parse(rslt.data.keywordMiner);
        if (body === false) {
          this.$store.commit("setIsSubscriber", false);
          this.$router.push({ name: "Default" });
          return;
        }
        const data = body.data;
        if (data == void 0 || data.items.length <= 0) {
          this.showMessege = true;
          return;
        }
        data.items.forEach(item => this.items.push(item));
        this.makePagination(data.total);
        this.disabledDownloadCsv = false;
      }
      catch(e) {
        if (e.errors != void 0 && e.errors[0].errorType === 'ExecutionTimeout' || e.errors[0].errorType === 'Lambda:Unhandled') {
          this.messageType = MESSAGE_TIMEOUT;
          this.showMessege = true;
          return;
        }
        throw e;
      }
      finally {
        this.loading = false;
      }
    },
    makePagination(count) {
      //10000件を超えるとqueryProductでページ指定できないので最大10000件とする。
      this.pagination.count = count > 10000 ? 10000 : count;
      this.pagination.lastpage = parseInt(Math.ceil(this.pagination.count / this.pagination.countPerPage));
      this.pagination.start = (this.page - 1) * this.pagination.countPerPage;
      if (this.page == this.pagination.lastpage) {
        this.pagination.end = this.pagination.count;
      }
      else {
        this.pagination.end = this.pagination.start + this.pagination.countPerPage;
      }
    },
    price(item, val) {
      const price = val != void 0 ? val.toLocaleString() : '-';
      if (item.marketplace === MARKETPLACE_JP) {
        return this.t('{0}円', [price]);
      }
      return `$${price}`;
    },
    initializeHistoryDateList() {
      this.historyDateList = [{ name: this.t('過去30日'), value: null }];
      for (const i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) {
        const yyyymm = moment().add(-i, 'months');
        this.historyDateList.push({ name: yyyymm.format('YYYY-MM'), value: yyyymm.format('YYYYMM') });
      }
      this.historyDate = this.historyDateList[0];
    },
    historyDateSelected(item) {
      this.historyDate = item;
    },
    createCsvData(items) {
      const rate = val => {
        if (val > 0) {
          return (Math.round(val * 10000) / 100).toFixed(2);
        }
        return '-';
      };
      const round = val => {
        if (val > 0) {
          return (Math.round(val * 10) / 10).toFixed(1);
        }
        return '-';
      };
      const data = items.map(item => {
        const avgDailySearches = item.searches == void 0 || item.searches == 0 ? 0 : parseInt(item.searches / 30);
        const conversionRate = item.searches == void 0 || item.searches == 0 ? 0 : item.purchases / item.searches;
        const supplyDemandRatio = item.products == void 0 || item.products == 0 ? 0 : item.searches / item.products;
        return [
          item.keyword, // キーワード
          item.amazonChoice === true ? 1 : 0, // Amazon Choice
          item.departments.map(d => d.label).join(','), // カテゴリー
          item.searches > 0 ? item.searches : '-', // 月間検索数
          avgDailySearches > 0 ? avgDailySearches : '-', // 日平均検索数
          item.purchases > 0 ? item.purchases : '-', // 月間販売数
          rate(conversionRate), // 転換率
          item.spr > 0 ? item.spr : '-', // SPR
          item.titleDensity > 0 ? item.titleDensity : '-', // タイトル密度
          item.products > 0 ? item.products : '-', // 商品数
          round(supplyDemandRatio), // 需給比
          item.adProducts > 0 ? item.adProducts : '-', // 広告ライバル商品数
          rate(item.monopolyClickRate), // クリック集中度
          rate(item.cvsShareRate), // 転換総数比率
          item.bid != void 0 ? item.bid : '-', // PPC入札額
          item.bidMin != void 0 ? item.bidMin : '-', // PPC入札額(From)
          item.bidMax != void 0 ? item.bidMax : '-', // PPC入札額(To)
          item.avgPrice != void 0 ? item.avgPrice : '-', // 市場分析
          item.avgRating, // 星評価
        ].map(s => this.encloseString(s == void 0 || s.length <= 0 ? '-' : s)).join(',')
      });
      const title = this.t('keywordMiningCsvheader');
      return `${title}\n${data.join('\n')}`;
    },
    encloseString(str) {
      const s = str.toString();
      if (s.includes(',')) {
        return `"${s.replace(/"/g, '""')}"`;
      }
      return s;
    },
    async downloadCsv() {
      const text = this.createCsvData(this.items);
      this.downloadText(text, 'text/csv', `keywordmining_${moment().format('YYYYMMDDHHmmss')}.csv`);
    },
    async downloadBatchCsv() {
      this.isProcessing = true;
      try {
        let items = [];
        const option = { historyDate: this.lastSearchHistoryDate.value };
        const rslt = await API.graphql(
          graphqlOperation(queries.keywordMiner, { domain: this.lastSearchDomain, keyword: this.lastSearchWords, option: JSON.stringify(option), batchCount: 3000 })
        );
        if (rslt.data.keywordMiner != void 0) {
          const body = JSON.parse(rslt.data.keywordMiner);
          if (body === false) {
            this.$store.commit("setIsSubscriber", false);
            this.$router.push({ name: "Default" });
            return;
          }
          items = body.map(c => c.data.items).flat();
        }
        const text = this.createCsvData(items);
        this.downloadText(text, 'text/csv', `keywordmining_${moment().format('YYYYMMDDHHmmss')}.csv`);
      }
      catch(e) {
        if (e.errors != void 0 && e.errors[0].errorType === 'ExecutionTimeout' || e.errors[0].errorType === 'Lambda:Unhandled') {
          this.messageType = MESSAGE_TIMEOUT;
          this.showMessege = true;
          return;
        }
        throw e;
      }
      finally {
        this.isProcessing = false;
      }
    },
    searchKeyup(e) {
      const value = this.$refs.candidateList.select(e.keyCode);
      if (value.length > 0) {
        this.searchWords = value;
        return;
      }
      this.$refs.candidateList.showCandidates(e.target);
    },
    selectedKeyword(keyword) {
      this.searchWords = keyword;
      if (this.searchWords != void 0) {
        this.search();
      }
    },
    searchBlur(e) {
      if (e.relatedTarget != void 0) {
        this.$refs.candidateList.close();
      }
    },
  },
}
</script>

<style scoped>
.list {
  font-size: 10pt;
}

.list-title {
  background-color: lightgrey;
  font-weight: bold;
  border-bottom: 1px solid lightgrey;
  padding: 4px;
}

.list-detail {
  background-color: white;
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid #ddd;
  padding: 4px;
}

.list-table {
  display:grid;
  grid-template-columns: 4em 1fr 10em 7em 6em 3em 7em 4em 4em 10em 8em 10em 6em;
}

.tag-amazons-choice {
  background-color: #373e99;
}

.downloadcsv {
  background-color: #379992;
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}
</style>
