<template>
  <div class="page-content">
    <div class="container">
      <manual-link :href="manuallink">{{ t('SEOリサーチ') }}</manual-link>
      <b-alert show variant="warning">{{ t('ベータ版機能テスト中') }}</b-alert>

      <!-- PC -->
      <div class="d-none d-md-block">
        <div class="max-w-md d-flex flex-column m-2">
          <div class="search input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <svg width="1.1em" height="1.1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"></path>
                  <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"></path>
                </svg>
              </span>
            </div>
            <input
              type="text"
              class="form-control py-4"
              :placeholder="t('キーワード')"
              :aria-label="t('キーワード')"
              aria-describedby="search-product"
              v-model="searchWords"
              @keydown.enter="search"
              @keyup="searchKeyup"
              @blur="searchBlur"
            />
            <div class="input-group-append">
              <b-button class="btn bg-eresa text-white px-3" type="button" @click="search">{{ t('検索') }}</b-button>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="d-md-none">
        <div class="d-flex flex-wrap m-2">
          <div class="w-100 d-flex flex-column">
            <div class="search input-group">
              <input
                ref="search"
                type="text"
                class="form-control py-4"
                :placeholder="t('キーワード')"
                :aria-label="t('キーワード')"
                aria-describedby="search-product"
                v-model="searchWords"
                @keydown.enter="search"
                @keyup="searchKeyup"
                @blur="searchBlur"
              />
              <div class="input-group-append">
                <b-button
                  class="btn bg-eresa text-white px-3"
                  type="button"
                  @click="search"
                  id="search-product"
                  ><font-awesome-icon :icon="['fas', 'magnifying-glass']" style="font-size:24px" />
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <candidate-window ref="candidateList" @selected="selectedKeyword" />

      <span class="text-secondary small ml-2 d-none d-sm-block">{{ t('リアルタイムのAmazon上の検索結果順（SEO順）で商品一覧を表示します。') }} {{ t('※データの取得には少々お時間がかかることがございます。') }}</span>
      <span class="text-secondary small ml-2 d-block d-sm-none smalltext">{{ t('リアルタイムのAmazon上の検索結果順（SEO順）で商品一覧を表示します。') }}</span>
      <span class="text-secondary small ml-2 d-block d-sm-none smalltext">{{ t('※データの取得には少々お時間がかかることがございます。') }}</span>
    </div>

    <b-alert :show="showMessege && messageType === MESSAGE_NOTFOND" variant="info">{{ t('{0}の検索に一致する商品はありませんでした。', [lastSearchWords]) }}</b-alert>
    <b-alert :show="showMessege && messageType === MESSAGE_TIMEOUT" variant="info">{{ t('検索が失敗しました。再度検索を試してください。※アクセスが集中するピーク時間帯には検索が失敗する場合がありますので、何度か試みても問題が解決されない場合は、時間を空けてから再度お試しください。') }}</b-alert>

    <div v-if="items.length > 0" class="container mt-4">
      <div class="d-none d-sm-flex">
        <div class="avg-table">
          <div class="text-secondary small text-right font-weight-bold">{{ t('新品平均価格帯') }}</div>
          <div class="text-secondary small text-right font-weight-bold">{{ t('中古平均価格帯') }}</div>
          <div class="text-secondary small text-right font-weight-bold">{{ t('平均ランキング') }}</div>
          <div class="text-secondary small text-right font-weight-bold">{{ t('平均販売期間') }}</div>
          <div class="text-secondary small text-right font-weight-bold">{{ t('平均評価数') }}</div>
          <div class="text-secondary small text-right">{{ avg.newPriceRange }}</div>
          <div class="text-secondary small text-right">{{ avg.usedPriceRange }}</div>
          <div class="text-secondary small text-right">{{ avg.ranking }}</div>
          <div class="text-secondary small text-right">{{ avg.salesPeriod }}</div>
          <div class="text-secondary small text-right">{{ avg.numberOfReviews }}</div>
        </div>
        <div style="margin: 0 0 0 auto">
          <b-button class="btn aianalysis bg-eresa text-white mr-2" :disabled="disabledAiAnalysis ? disabledAiAnalysis : void 0" @click="aianalysis">{{ t('AIによる商品タイトル、SEOキーワードの提案') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
          <b-button class="btn aianalysis bg-eresa text-white" :disabled="disabledAiAnalysis ? disabledAiAnalysis : void 0" @click="aianalysisProduct">{{ t('AIによる関連キーワードや商品の提案') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
        </div>
      </div>
      <div class="d-flex d-sm-none">
        <div>
          <div class="text-secondary small"><span class="font-weight-bold">{{ t('新品平均価格帯') }}:</span> {{ avg.newPriceRange }}</div>
          <div class="text-secondary small"><span class="font-weight-bold">{{ t('中古平均価格帯') }}:</span> {{ avg.usedPriceRange }}</div>
          <div class="text-secondary small"><span class="font-weight-bold">{{ t('平均ランキング') }}:</span> {{ avg.ranking }}</div>
          <div class="text-secondary small"><span class="font-weight-bold">{{ t('平均販売期間') }}:</span> {{ avg.salesPeriod }}</div>
          <div class="text-secondary small mb-2"><span class="font-weight-bold">{{ t('平均評価数') }}:</span> {{ avg.numberOfReviews }}</div>
          <b-button class="btn aianalysis bg-eresa text-white" :disabled="disabledAiAnalysis ? disabledAiAnalysis : void 0" @click="aianalysis">{{ t('AIによる商品タイトル、SEOキーワードの提案') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
          <b-button class="btn aianalysis bg-eresa text-white" :disabled="disabledAiAnalysis ? disabledAiAnalysis : void 0" @click="aianalysisProduct">{{ t('AIによる関連キーワードや商品の提案') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button>
        </div>
      </div>
    </div>

    <div v-if="items.length > 0" class="container mb-5">
      <div class="card shadow border-0 pb-4">
        <div class="card-body">
          <result-list ref="resultList" :key="resultListKey" sellerCountry @loaded="loaded"></result-list>
        </div>
      </div>
    </div>

    <Loading v-else-if="loading"></Loading>
    <ai-chat-window ref="aichat"></ai-chat-window>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import ManualLink from '@/components/ManualLink.vue';
import ResultList from '@/components/ResultList.vue';
import Loading from '@/components/Loading.vue';
import CandidateWindow from '@/components/CandidateWindow.vue';
import AiChatWindow from '@/components/AiChatWindow';
import Utils from '@/mixins/utils';
import AuthUtil from '@/mixins/authutil';
import { ProductInfo } from '@/mixins/ProductInfo';
import { AIChat } from '@/mixins/AIChat';

const MANUAL_JP = 'https://pro.eresa.jp/function/seo-research/';
const MANUAL_COM = 'https://eresa.io/function/seo-research/';

const MAX_ROWS = 50;
const MESSAGE_NOTFOND = 0;
const MESSAGE_TIMEOUT = 1;

export default {
  name: 'SeoResearch',
  components: {
    ManualLink,
    ResultList,
    Loading,
    CandidateWindow,
    AiChatWindow,
  },
  mixins: [Utils, AuthUtil],
  data() {
    return {
      MESSAGE_NOTFOND,
      MESSAGE_TIMEOUT,
      searchWords: null,
      items: [],
      showMessege: false,
      messageType: MESSAGE_NOTFOND,
      resultListKey: 0,
      loading: false,
      disabledAiAnalysis: true, // AI分析非活性フラグ
      lastSearchWords: null,
      lastSearchDomain: null,
      avg: { newPriceRange: '-', usedPriceRange: '-', ranking: '-', salesPeriod: '-', numberOfReviews: '-' },
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
    }
  },
  async created() {
    if (this.$route.query.domain != void 0) {
      const isCom = this.isComDomain(this.$route.query.domain);
      this.$store.commit("setDomainCom", isCom);
    }
    this.searchWords = this.$route.params.words;
  },
  async mounted() {
    if (this.searchWords != void 0 && this.searchWords !== '') {
      this.search();
    }
    await this.validateSubscriber();
  },
  computed: {},
  watch: {},
  methods: {
    async search() {
      if (this.searchWords == void 0 || this.searchWords === '') {
        console.info("input empty");
        return;
      }

      this.lastSearchWords = this.searchWords;
      this.lastSearchDomain = this.$store.getters.getDomain;
      this.showMessege = false;
      this.messageType = MESSAGE_NOTFOND;
      this.resultListKey++;
      await this.showList();
    },
    async showList() {
      this.avg.newPriceRange = '-';
      this.avg.usedPriceRange = '-';
      this.avg.ranking = '-';
      this.avg.salesPeriod = '-';
      this.avg.numberOfReviews = '-';
      try {
        this.disabledAiAnalysis = true;
        this.loading = true;
        this.items = [];
        const rslt = await API.graphql(
          graphqlOperation(queries.search, { words: this.lastSearchWords, domain: this.lastSearchDomain, isRainforest: true })
        );
        const items = rslt.data.search;
        if (items == void 0 || items.length <= 0) {
          this.showMessege = true;
          return;
        }
        items.slice(0, MAX_ROWS).forEach(item => this.items.push(JSON.parse(item)));
        this.items.forEach(item => {item.domainId = this.lastSearchDomain});
        requestAnimationFrame(() => this.$refs.resultList.addProducts(this.items, true));
      }
      catch(e) {
        if (e.errors != void 0 && e.errors[0].errorType === 'ExecutionTimeout') {
          this.messageType = MESSAGE_TIMEOUT;
          this.showMessege = true;
          return;
        }
        throw e;
      }
      finally {
        this.loading = false;
      }
    },
    loaded() {
      this.disabledAiAnalysis = false;
      // 新品平均価格帯
      const newPrices = this.$refs.resultList.list
        .filter(item => item.stats.current[ProductInfo.CSV_NAME_INDEXES.NEW] > 0)
        .map(item => item.stats.current[ProductInfo.CSV_NAME_INDEXES.NEW]);
      const newPrice = newPrices.reduce((p, c) => p + c, 0);
      if (newPrice > 0) {
        const m = newPrice / newPrices.length;
        const std = Math.sqrt(newPrices.map(x => (x - m) * (x - m)).reduce((p, c) => p + c) / newPrices.length);
        const from = this.round(Math.max(m - std, Math.min(...newPrices)));
        const to = this.round(Math.min(m + std, Math.max(...newPrices)));
        this.avg.newPriceRange = `${ProductInfo.price(this.$refs.resultList.domainId, from)} ${this.t('～')} ${ProductInfo.price(this.$refs.resultList.domainId, to)}`;
      }
      // 中古平均価格帯
      const usedPrices = this.$refs.resultList.list
        .filter(item => item.stats.current[ProductInfo.CSV_NAME_INDEXES.USED] > 0)
        .map(item => item.stats.current[ProductInfo.CSV_NAME_INDEXES.USED]);
      const usedPrice = usedPrices.reduce((p, c) => p + c, 0);
      if (usedPrice > 0) {
        const m = usedPrice / usedPrices.length;
        const std = Math.sqrt(usedPrices.map(x => (x - m) * (x - m)).reduce((p, c) => p + c) / usedPrices.length);
        const from = this.round(Math.max(m - std, Math.min(...usedPrices)));
        const to = this.round(Math.min(m + std, Math.max(...usedPrices)));
        this.avg.usedPriceRange = `${ProductInfo.price(this.$refs.resultList.domainId, from)} ${this.t('～')} ${ProductInfo.price(this.$refs.resultList.domainId, to)}`;
      }
      // 平均ランキング
      const rankings = this.$refs.resultList.list.map(item => new ProductInfo(item).ranking.ranking).filter(item => item > 0);
      if (rankings.length > 0) {
        this.avg.ranking = this.t('{0}位', [Math.round(rankings.reduce((p, c) => p + c) / rankings.length)]);
      }
      // 平均販売期間
      const months = this.$refs.resultList.list.map(item => new ProductInfo(item).listedMonths).filter(item => item != void 0);
      if (months.length > 0) {
        this.avg.salesPeriod = this.t('{0}ヵ月', [Math.round(months.reduce((p, c) => p + c) / months.length)]);
      }
      // 平均評価数
      const nreviews = this.$refs.resultList.list.map(item => item.ratings_total).filter(r => r > 0);
      if (nreviews.length > 0) {
        this.avg.numberOfReviews = Math.round(nreviews.reduce((p, c) => p + c) / nreviews.length);
      }
    },
    round(value) {
      const d = Math.floor(Math.log10(value));
      return Math.round(value / Math.pow(10, d - 1)) * Math.pow(10, d - 1);
    },
    async aianalysis() {
      const titles = this.$refs.resultList.createTitleData();
      const message = AIChat.createMessageFromSeoResearch(this.searchWords, titles);
      this.$refs.aichat.show(message, true);
    },
    async aianalysisProduct() {
      const message = AIChat.createMessageFromSeoResearchProduct(this.searchWords);
      this.$refs.aichat.show(message, true);
    },
    searchKeyup(e) {
      const value = this.$refs.candidateList.select(e.keyCode);
      if (value.length > 0) {
        this.searchWords = value;
        return;
      }
      this.$refs.candidateList.showCandidates(e.target);
    },
    selectedKeyword(keyword) {
      this.searchWords = keyword;
      if (this.searchWords != void 0) {
        this.search();
      }
    },
    searchBlur(e) {
      if (e.relatedTarget != void 0) {
        this.$refs.candidateList.close();
      }
    },
  },
}
</script>

<style scoped>
.aianalysis {
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}

.aianalysis {
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}

.smalltext {
  font-size: 6pt;
}

.avg-table {
  display:grid;
  grid-template-columns: 10em 10em 7em 7em 7em;
}
</style>
