export default {
  "トラッキング": "Tracking",
  "ベータ版機能テスト中": "Beta functionality is being tested",
  "JAN": "EAN/UPC",
  "メーカー": "Manufacturer",
  "取り扱い開始日": "Listed since",
  "価格": "Price",
  "新品": "New",
  "新品(FBA)": "New (FBA)",
  "新品（送料込）": "New FBM shipping",
  "中古": "Used",
  "中古(FBA)": "Used (FBA)",
  "中古（送料込）": "Used shipping",
  "カート": "Cart",
  "参考価格": "Reference price",
  "コレクター商品": "Collector",
  "整備済み品": "Refurbished",
  "タイムセール": "Limited-time sale",
  "タイムセールが開始されたら通知を受け取る": "Receive notifications when time sales begin",
  "アイテム数": "Number of items",
  "評価": "Evaluation",
  "売れ筋ランキング": "BSR",
  "評価(1.0～5.0)": "Rating (1.0-5.0)",
  "レビュー数": "Number of reviews",
  "その他": "Others",
  "メモ": "Memo",
  "タグ": "Tag",
  "トラッキング期間": "Tracking period",
  "リセット": "Reset",
  "トラッキング開始": "Start tracking",
  "30日": "30 days",
  "90日": "90 days",
  "180日": "180 days",
  "1年": "1 year",
  "トラッキング開始に失敗しました。": "Failed to start tracking.",
  "これ以上登録できません。": "No more registrations can be made.",
}
